import React, { useState, useEffect, useMemo } from "react"
import { StaticImage } from "gatsby-plugin-image"
import homeBannerImg from "images/Home/Slider/home_banner_v2.jpg"
import prevSliderIcon from "images/prev_icon_slider.svg"
import nextSliderIcon from "images/next_icon_slider.svg"
import banner1VI from "images/Home/Slider/home-slider-1-vi.png"
import banner1EN from "images/Home/Slider/home-slider-1-en.png"

import banner1MobileVI from "images/Home/Slider/mobile-home-slider-1-vi.png"
import banner1MobileEN from "images/Home/Slider/mobile-home-slider-1-en.png"
import banner2 from "images/Home/Slider/home-slider-2.png"
import banner3 from "images/Home/Slider/home-slider-3.png"
import banner4 from "images/Home/Slider/home-slider-4.png"
import banner5 from "images/Home/Slider/home-slider-5.png"

import bannerLivestreamViMobile from "images/Home/Slider/banner-livestream-vi-mobile.png"
import bannerLivestreamEnMobile from "images/Home/Slider/banner-livestream-en-mobile.png"
import bannerLivestreamViDesktop from "images/Home/Slider/banner-livestream-vi-desktop.png"
import bannerLivestreamEnDesktop from "images/Home/Slider/banner-livestream-en-desktop.png"

import banner6Desktop from "images/Home/Slider/banner-top-company-desktop.png"
import banner6Mobile from "images/Home/Slider/banner-top-company-mobile.png"

import Slider from "react-slick/lib/slider"
import "./SliderHome.scss"
import ContainerMain from "src/pages/common/ContainerMain"
import { useFormatter } from "helpers/i18n"

const image1 = "../../../images/Home/Slider/image-1.png"
const image2 = "../../../images/Home/Slider/image-2.png"
const image3 = "../../../images/Home/Slider/image-3.png"
const image4 = "../../../images/Home/Slider/image-4.png"

export default function SliderHome() {
  const { __, currentLocale } = useFormatter()
  const [activeSlide, setActiveSlide] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 1024) // Assuming 1024 or less is mobile
    // If mobile, set the first slide to the mobile version
  }
  useEffect(() => {
    // On component mount, check for mobile
    checkMobile()
    // Add event listener to check for mobile on window resize
    window.addEventListener("resize", checkMobile)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkMobile)
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: "linear",
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setActiveSlide(current),
    appendDots: dots => (
      <ul className="slick-dots" style={{ display: "block" }}>
        {dots.map((dot, index) => (
          <span key={index} onClick={e => e.stopPropagation()}>
            {dot}
          </span>
        ))}
      </ul>
    ),
  }

  const banner1 = currentLocale === "vi" ? banner1VI : banner1EN

  const bannerLivestream =
    currentLocale === "vi"
      ? bannerLivestreamViDesktop
      : bannerLivestreamEnDesktop

  const slides = [
    {
      backgroundImage: banner6Desktop,
      clickUrl:
        "https://cafef.vn/onpoint-duoc-vinh-danh-top-100-noi-lam-viec-tot-nhat-viet-nam-188241125163420195.chn",
    },
    {
      backgroundImage: bannerLivestream,
      clickUrl: "/livestream",
    },
    {
      backgroundImage: banner1,
      clickUrl: "https://onpoint.vn/media/best-managed-companies-2024/",
    },
    {
      title1: __({
        defaultMessage:
          "Your trusted partner with a proven track record and extensive industry experience",
      }),
      title2: __(
        {
          defaultMessage: "Award-winning {br}E-commerce Service Provider",
        },
        { br: <br /> }
      ),
      imageFooters: [
        <StaticImage
          className="bQLobhqdez"
          layout="fullWidth"
          src={image1}
          alt="Home Slider"
          loading="lazy"
          key="footer2"
          quality={100}
        />,
        <StaticImage
          className="bQLobhqdez"
          layout="fullWidth"
          src={image2}
          alt="Home Slider"
          loading="lazy"
          key="footer1"
          quality={100}
        />,
        <StaticImage
          className="bQLobhqdez"
          layout="fullWidth"
          src={image3}
          alt="Home Slider"
          loading="lazy"
          key="footer1"
          quality={100}
        />,
        <StaticImage
          className="bQLobhqdez"
          layout="fullWidth"
          src={image4}
          alt="Home Slider"
          loading="lazy"
          key="footer2"
          quality={100}
        />,
      ],
      imageBanner: <img src={banner2} alt="" />,
    },
    {
      title1: __({
        defaultMessage: "Boost your business efficiency in impactful ways",
      }),
      title2: __({
        defaultMessage: "One-stop Solutions & Tailored Services",
      }),
      imageBanner: <img src={banner3} alt="" loading="lazy" />,
    },
    {
      title1: __({
        defaultMessage:
          "Become a pioneer in digital commerce utilizing the most advanced technologies",
      }),
      title2: __(
        {
          defaultMessage: "State-of-the-art & {br}Data-driven Products",
        },
        { br: <br /> }
      ),
      imageBanner: <img src={banner4} alt="" loading="lazy" />,
    },
    {
      title1: __({
        defaultMessage: "Accelerate your e-commerce growth with us",
      }),
      title2: __(
        {
          defaultMessage:
            "Southeast Asia's Leading{br}E-commerce Business Partner",
        },
        { br: <br /> }
      ),
      imageBanner: <img src={banner5} alt="" loading="lazy" />,
    },
  ].map((slide, index) => ({ ...slide, id: index }))

  const backgroundStyles = useMemo(() => {
    if (isMobile) {
      if (currentLocale == "vi") {
        return [
          {
            backgroundImage: `url(${banner6Mobile})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          },
          {
            backgroundImage: `url(${bannerLivestreamViMobile})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          },
          {
            backgroundImage: `url(${banner1MobileVI})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          },
          { backgroundImage: `url(${homeBannerImg})` },
          { backgroundImage: `url(${homeBannerImg})` },
          { backgroundImage: `url(${homeBannerImg})` },
          { backgroundImage: `url(${homeBannerImg})` },
        ]
      } else {
        return [
          {
            backgroundImage: `url(${banner6Mobile})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          },
          {
            backgroundImage: `url(${bannerLivestreamEnMobile})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          },
          {
            backgroundImage: `url(${banner1MobileEN})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          },
          { backgroundImage: `url(${homeBannerImg})` },
          { backgroundImage: `url(${homeBannerImg})` },
          { backgroundImage: `url(${homeBannerImg})` },
          { backgroundImage: `url(${homeBannerImg})` },
        ]
      }
    } else {
      return [
        { backgroundImage: `url(${homeBannerImg})` },
        { backgroundImage: `url(${homeBannerImg})` },
        { backgroundImage: `url(${homeBannerImg})` },
        { backgroundImage: `url(${homeBannerImg})` },
        { backgroundImage: `url(${homeBannerImg})` },
        { backgroundImage: `url(${homeBannerImg})` },
        { backgroundImage: `url(${homeBannerImg})` },
        // { backgroundImage: `url(${homeBannerImg})` },
      ]
    }
  }, [isMobile, currentLocale])

  const onClickBanner = item => {
    if (item?.clickUrl) {
      window.open(item.clickUrl, "_blank")
    }
  }

  return (
    <>
      <div
        className="bdkTtOROci"
        style={{
          cursor: slides[activeSlide]?.clickUrl ? "pointer" : "default",
        }}
        onClick={() => onClickBanner(slides[activeSlide])}
      >
        <div
          className="RAiNllHuyS"
          // style={{
          //   backgroundImage: `url(${backgroundSlider})`,
          // }}
          style={{ ...backgroundStyles[activeSlide] }}
        >
          <Slider className="uSDdEFzHYz" {...settings}>
            {slides.map((item, index) => {
              return (
                <div className="ABlEMYolHz" key={`${item.id}-${index}`}>
                  <div className="lpvVqGcPTv-pc">
                    <img
                      // layout="fullWidth"
                      src={item.backgroundImage || homeBannerImg}
                      alt=""
                      // decoding="async"
                      // fetchpriority="high"
                    />
                  </div>
                  <div className="qIfYHhThla">
                    <ContainerMain>
                      <div className="DEfBfkoSLD">
                        <div className="qBajkTIXyz">
                          <div className="cuTmJTzgUC-sub-title">
                            {item.title1}
                          </div>
                          <div className="cuTmJTzgUC">
                            <p>{item.title2}</p>
                          </div>
                          {item.imageFooters && (
                            <div className="oaUyNLhdRP">
                              {item.imageFooters.map((footerImg, idx) => {
                                return (
                                  <div className="wrapFooter" key={idx}>
                                    {footerImg}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>
                        <div className="DCJiCEYvSD">{item.imageBanner}</div>
                      </div>
                    </ContainerMain>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={nextSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#eee", fontSize: 30 }}
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={prevSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#eee", fontSize: 30 }}
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
    />
  )
}
